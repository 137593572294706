<template>
    <section class="h-100">
        <div class="d-flex f-15 mx-0 border-bottom align-items-center" style="height:40px;">
            <div
            class="h-100 col d-middle-center cr-pointer"
            :class="tab=='informacion'? 'border-b-black text-general':'text-general2'"
            @click="tab='informacion'"
            >
                Información
            </div>
            <div
            v-if="$can('tab3_leecheros_productos_stock')"
            class="h-100 col d-middle-center cr-pointer"
            :class="tab=='stock'? 'border-b-black text-general':'text-general2'"
            @click="tab='stock'"
            >
                Stock
            </div>
            <div
            class="h-100 col d-middle-center cr-pointer"
            :class="tab=='comentarios'? 'border-b-black text-general':'text-general2'"
            @click="tab='comentarios'"
            >
                Comentarios
            </div>
        </div>
        <div style="height: calc(100% - 48px);">
            <sinDatos v-if="id_producto === null" icon="mercado" mensaje="Seleccione uno de los productos del panel central." />
            <informacion v-show="id_producto && tab == 'informacion'" />
            <!-- <registro v-show="id_producto && tab == 'registro'" /> -->
            <stock v-show="id_producto && tab == 'stock'" :admin="false" :show-tab-stocks="tab == 'stock'" />
            <comentario v-show="id_producto && tab == 'comentarios'" :id="id_producto" ref="comentarios" :modelo="2" :tendero="$usuario.rol == 2 || $usuario.rol == 4" />
        </div>
    </section>
</template>

<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
import Productos from "~/services/productos/productos"
export default {
    components: {
        //registro: () => import('./registro'),
        stock: () => import('~/pages/productos/admin/stock'),
        informacion: () => import('./informacion.vue')
    },
    data(){
        return {
            tab: 'informacion',
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
        }),
    }
}
</script>
